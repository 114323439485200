import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseDeviceData, DeviceInfo, Response, ResponseData, Point, DataPoints, Selectivity } from 'src/app/common/models/antar-api-response.model';
import { AntarConstants } from 'src/app/common/antar.constants';
import { MultivendorSelectivityByTableRequestData, RequestData } from 'src/app/common/models/antart-api-request.model';
@Injectable( {
	providedIn: 'root'
} )

export class LoadDataService {
	apiUrl: string = '';
	antarCurveGenerationApiUrl = environment.apiUrl + 'GenerateTCCData';
	selectivityResultApiUrl = environment.apiUrl + 'ProtectionSelectivity/GetCurveSelectivityResult';
	combinedSelectivity = environment.apiUrl + 'ProtectionSelectivity/GetCombinedSelectivity';
	multivendorApiUrl = environment.multivendorApiUrl;
	launchSystem = environment.launchSystem;
	commonResponse!:Response;
	multivendorSelectivityByTableApiUrl = environment.multivendorSelectivityByTableApiUrl;
	point: number[][] = [];
	constructor ( private http: HttpClient ) {
		this.commonResponse = { data: null };
		if ( this.launchSystem !== undefined )
		{
			console.log( 'environment.lunchsystem ', this.launchSystem );
			this.apiUrl = this.launchSystem == AntarConstants.LAUNCHSYSTEM.ANTARES ? this.antarCurveGenerationApiUrl : this.multivendorApiUrl;
		}
	}
	fetchGraphPoints ( curveData: RequestData ): Observable<ResponseData> {
		if ( this.launchSystem === AntarConstants.LAUNCHSYSTEM.CEL ) {
			return this.http.post<ResponseData>( this.multivendorApiUrl, curveData ).pipe(
				map( response => {
					response.tccDataResponse.curveDataPoints.forEach( curvedata => {
						if ( curvedata['settingsData'] === undefined ) {
							curvedata.settingsData = {
								deviceId: curvedata.deviceId,
								deviceType: 'Circuit Breaker',
								deviceLegendName: curvedata.deviceLegendName,
								legendDataList: curvedata.legendDataList !== null ? curvedata.legendDataList : null
							};
						}
					} );
					return response;
				} )
			);
		} else {
			const requestDataForApi:RequestData = {
				applicationName: curveData.applicationName,
				data: curveData.data.filter( item => item.protectionDeviceType != AntarConstants.LABEL_TYPE['TRANSFORMER'] ),
				networkParameters: curveData.networkParameters,
				limits: curveData.limits
			};
			return this.http.post<Response>( this.apiUrl, requestDataForApi ).pipe(
				map( response => response.data )
			);
		}
	}
	getSelectivityResult ( curvePointsData: ResponseDeviceData[], requestData:RequestData ): Observable<Selectivity> {
		let points : Point[];
		let dataPoints : DataPoints [];
		const deviceData: DeviceInfo[] = curvePointsData.map( ( data: ResponseDeviceData ) => {
		if ( this.launchSystem === AntarConstants.LAUNCHSYSTEM.ANTARES ) {
			points = data.points ? data.points : [] ;
			dataPoints = [];

		} else {
			dataPoints = data.dataPoints ? data.dataPoints : [];
			points = [];
				}
			return {
				deviceId: data.deviceId,
				breakerPositioning: data.breakerPositioning,
				points: points,
				dataPoints: dataPoints
			};
		} );
		return this.launchSystem === AntarConstants.LAUNCHSYSTEM.ANTARES ? this.http.post<Response>( this.combinedSelectivity, requestData )
		.pipe( map( response => response.data ) ) : this.http.post<Response>( this.selectivityResultApiUrl, { 'curveDataContent': deviceData } )
				.pipe( map( response => response.data ) );
	}
	getSelectivityResultByTable ( cbData: RequestData ): Observable<Response> {
		const multivendorSelectivityRequestData: MultivendorSelectivityByTableRequestData = {
			requests: []
		};
		let upstreamproductcode: string = '';
		let downstreamproductcode: string = '';

			cbData.data.forEach( item => {
				if ( item.breakerPositioning == 'incomer' )
				{
					upstreamproductcode = item.productCode ?? '';
				}
				if ( item.breakerPositioning == 'outgoing' )
				{
					downstreamproductcode = item.productCode ?? '';
				}
				multivendorSelectivityRequestData.requests = [ {
					upstreamProductCode: upstreamproductcode,
					downstreamProductCode: downstreamproductcode,
					nominalVoltage: item.deviceParameters?.voltage,
					manufacturer: item.manufacturerId
				} ];
			} );
				return this.http.post( this.multivendorSelectivityByTableApiUrl, multivendorSelectivityRequestData, { responseType: 'text' } )
				.pipe(
				switchMap( ( response: string ) => {
					this.commonResponse = { ...this.commonResponse, data: response };
					return of( this.commonResponse );
				} )
				);

	}
}

