import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromStore from './app.reducer';
import { DataPoints, ResponseDeviceData } from '../common/models/antar-api-response.model';
import { AntarConstants } from '../common/antar.constants';
import { RequestData } from '../common/models/antart-api-request.model';
export const getAppState = createFeatureSelector<fromStore.State>( 'antar' );

export const getUser = createSelector(
	getAppState,
	( state: fromStore.State ) => state.user
);
export const getAllGroupIds = createSelector(
	getAppState,
    ( state: fromStore.State ) => {
        const allGroupIds :string[] = [];
        state.requestData.data.forEach( item => {
            if ( Array.isArray( item.groupId ) && item.groupId !== undefined ) {
				item.groupId.forEach( groupId => {
					if ( !allGroupIds.includes( groupId ) ) {
						allGroupIds.push( groupId );
					}
				} );
            }
        } );
        return allGroupIds;
    }
);
export const getInputRequestData = ( groupId: string ) => createSelector(
	getAppState,
	( state: fromStore.State ) => {
		const requestData: RequestData = {
            ...state.requestData,
            data: state.requestData.data.filter( item =>
                Array.isArray( item.groupId ) && item.groupId.includes( groupId )
            ),
			applicationName: state.requestData.applicationName,
			networkParameters: state.requestData.networkParameters
        };
		return requestData;
	}

);
export const getDeviceData = createSelector(
	getAppState,
	( state: fromStore.State ) => state['deviceData']
);
export const getSettingsData = createSelector(
	getAppState,
	( state: fromStore.State ) => {
		const deviceSettings = state.deviceSetting ? [ ...state.deviceSetting ] : [];
		deviceSettings.sort( ( a, b ) => {
		if ( a.breakerPositioning === AntarConstants.BREAKER_POSITIONING['incomer'] && b.breakerPositioning === AntarConstants.BREAKER_POSITIONING['outgoing'] ) {
			return -1;
		}
		if ( a.breakerPositioning === AntarConstants.BREAKER_POSITIONING['outgoing'] && b.breakerPositioning === AntarConstants.BREAKER_POSITIONING['incomer'] ) {
			return 1;
		}
		return 0;
	} );
		return { 'deviceSettings': deviceSettings };
	}
  );

export const getUserSettingsData = createSelector(
	getAppState,
	( state: fromStore.State ) => state.userSetting
);
export const getSelectivityResultData = createSelector(
	getAppState,
	( state: fromStore.State ) => ( { 'selectivity': state?.selectivity } )
);
export const getLoader = createSelector(
	getAppState,
	( state: fromStore.State ) => ( { 'loader': state.loader } )
);
export const getErrorMessage = createSelector(
	getAppState,
	( state: fromStore.State ) => state.notification
);
export const getRcdResponse = createSelector(
	getAppState,
	( state: fromStore.State ) => state.rcdDeviceResponse
);
export const getRcdSettings = createSelector(
	getAppState,
	( state: fromStore.State ) => state.rcdSettings
);
export const getUserSelectionChart = createSelector(
	getAppState,
	( state: fromStore.State ) => state.userSelection.chartType
);

export const getSelectedGroup = createSelector(
	getAppState,
	( state : fromStore.State ) => state.selectedGroup
);
export const getRcdDeviceData = createSelector(
	getAppState,
	( state: fromStore.State ) => state.rcdDeviceData
);
export const getDevicei2tData = createSelector(
	getDeviceData,
	( deviceDataList: ResponseDeviceData[] ) => {
		let i2tData: DataPoints[] = [];
		deviceDataList.forEach( deviceData => {
			const i2tpoints = deviceData.dataPoints?.filter( ( dataPoint: DataPoints ) => dataPoint.curveType === AntarConstants.CB_CURVE_TYPES['I2t'].toUpperCase() );
			if ( i2tpoints ) {
				i2tData = i2tData.concat( i2tpoints );
			}
		} );
		return i2tData;
	}
);
export const getSelectivityByTable = createSelector(
	getAppState,
	( state: fromStore.State ) => state.selectivityByTable
);
export const getTicCurveResponse = createSelector(
	getAppState,
	( state: fromStore.State ) => state.ticCurveResponse
);
